import React from 'react';
import PageBanner from '../../components/general/PageBanner';
import AllUsersTable from './AllUsersTable';
import PendingCampaigns from './PendingCampaigns';
import ActiveCampaigns from './ActiveCampaigns';
import SuccessfulCampaigns from './SuccessfulCampagins';
import FailedCampaigns from './FailedCampaigns';
import WaitingForApproval from './WaitingForApproval';
import useUser from '../../hooks/useUser';
import AppStats from './AppStats';
import TokensUSDPricing from './TokensUSDPricing';

function AdminPanel() {
    const { userInfo } = useUser();

    return (
        <>
            <PageBanner
                heading='Admin Panel'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, similique pariatur et corporis'
            ></PageBanner>
            <section className='pb-5 page-first-section'>
                <div className='container pb-5'>
                    <header className='mb-5'>
                        <h2 className='mb-0'>Hi {userInfo?.name}</h2>
                        <p className='text-muted'>Here're some stats about your app</p>
                    </header>

                    <div className='mb-3'>
                        <AppStats />
                    </div>

                    <div className='mb-3'>
                        <TokensUSDPricing />
                    </div>

                    <div className='mb-3'>
                        <WaitingForApproval />
                    </div>

                    <div className='mb-3'>
                        <AllUsersTable />
                    </div>

                    <div className='mb-3'>
                        <PendingCampaigns />
                    </div>

                    <div className='mb-3'>
                        <ActiveCampaigns />
                    </div>

                    <div className='mb-3'>
                        <SuccessfulCampaigns />
                    </div>

                    <div className='mb-3'>
                        <FailedCampaigns />
                    </div>

                    {/* <div className='mb-3'>
                        <PendingCampaigns />
                    </div>
                    <div className='mb-3'>
                        <UserActiveCampaigns />
                    </div>
                    <div className='mb-3'>
                        <UserSuccessfulCampaigns />
                    </div>
                    <div className='mb-3'>
                        <InvolvedCampaigns />
                    </div> */}
                </div>
            </section>
        </>
    );
}

export default AdminPanel;
