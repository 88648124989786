import React from 'react';
import PageBanner from '../../components/general/PageBanner';
import ActivityTable from './ActivityTable';

function ActivityPage() {
    return (
        <>
            <PageBanner
                heading='Recent Activities'
                text='Recent activities with FUNDUR - Web3 fundraising platform'
            ></PageBanner>
            <section className='pb-5 page-first-section'>
                <div className='container pb-5'>
                    <ActivityTable />
                </div>
            </section>
        </>
    );
}

export default ActivityPage;
