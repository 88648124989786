import React from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import useApp from '../../hooks/useApp';

function HeroBanner() {
    const { isRegistered } = useUser();
    const { themeMode } = useApp();

    return (
        <>
            <section className='hero-banner'>
                <div className='hero-banner-bg'></div>
                {themeMode === 'light' && <div className='hero-banner-bg-light'></div>}
                <div className='container z-index-20 hero-banner-container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6'>
                            <p className='h5 text-primary'>Web3 Crowdfunding platform</p>
                            <h1 className='text-xxl mb-3'>The world trusted crowdfunding app - FUNDUR</h1>
                            <p className='text-muted mb-3'>
                            FUNDUR platform enables small organisation to raise necessary funds via crowdfunding through smart contract transactions..
                            </p>
                            <ul className='list-inline'>
                                <li className='list-inline-item me-3'>
                                    <Link
                                        to={`${isRegistered ? '/create-campaign' : '/signup'}`}
                                        className='btn btn-primary'
                                    >
                                        Start a Fundraiser
                                    </Link>
                                </li>
                                <li className='list-inline-item'>
                                    <Link className='btn btn-link text-white' to='/about-us'>
                                        About Us
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className='col-lg-5 ms-auto text-center'>
                            <div className='oval-holder d-inline-block'>
                                <img src='/about-1-1.jpg' alt='' className='oval img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroBanner;
