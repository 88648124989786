import React from 'react';
import PageBanner from '../../components/general/PageBanner';
import CampaignForm from './CampaignForm';

function CreateCampaignPage() {
    return (
        <>
            <PageBanner
                heading='Create a Campiagn'
                text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, similique pariatur et corporis'
            ></PageBanner>
            <section className='pb-5 page-first-section'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-8 mx-auto'>
                            <div className='card p-md-4'>
                                <div className='card-body'>
                                    <header className='text-center mb-4'>
                                        <h4 className='mb-0'>Fill the Form Below</h4>
                                        <p className='text-muted'>To create a new campgain</p>
                                    </header>

                                    <CampaignForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CreateCampaignPage;
