import React, { useRef } from 'react';
import { appSettings } from '../../helpers/settings';
import { HiMail } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Axios from 'axios';

function Footer() {
    const formRef = useRef();
    const {
        register,
        resetField,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
          NEWSLETTER SUBSCRIPTION FUNCTION
    --------------------------------------------- */
    async function sendFormData() {
        try {
            await Axios.post(`https://formspree.io/f/${appSettings?.newsletterFormId}`, new FormData(formRef.current), {
                headers: {
                    Accept: 'application/json',
                },
            });
            toast.success('Thank you for subscribing');
            resetField('email');
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong');
        }
    }

    return (
        <footer className='main-footer pt-5'>
            <div className='container pt-5 pb-4'>
                <div className='row gy-4 gx-5 mb-4'>
                    <div className='col-xxl-3 col-lg-6'>
                        <img
                            src={`${appSettings?.logo}`}
                            alt={`${appSettings?.brandName}`}
                            width={appSettings.logoWidth}
                            className='img-fluid mb-3'
                        />
                        <p className='mb-4 text-sm text-muted'>
                        FUNDUR - Web3 fundraising App..
                        </p>
                        <ul className='list-unstyled p-0 text-sm'>
                            <li className='d-flex mb-2'>
                                <HiMail className='text-primary me-2' size={20} />
                                <a href='mailto:hello@fundur.org' className='text-reset'>
                                hello@fundur.org
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>Sitemap</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/'>
                                    Home
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/campaigns'>
                                Campaigns
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/activity'>
                                    Activities
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/about-us'>
                                    About Us
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/faqs'>
                                    Faqs
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>Resources</h4>
                        <ul className='list-unstyled mb-0 p-0 text-muted'>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/about-us'>
                                    About Us
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/faqs'>
                                    FAQs
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <a
                                    className='social-link bg-hover-primary'
                                    rel='noreferrer'
                                    href='https://fundur.org'
                                >
                                    FUNDUR
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className='col-xxl-3 col-lg-6'>
                        <h4 className='mb-3'>Newsletter Signup</h4>
                        <p className='mb-4 text-sm text-muted'>Subscribe to the newsletter and stay updated.</p>
                        <form className='position-relative' ref={formRef} onSubmit={handleSubmit(sendFormData)}>
                            <input
                                type='email'
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: 'Please enter your email',
                                    },
                                })}
                                name='email'
                                placeholder='Add your email'
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            />
                            {errors.email && <span className='invalid-feedback'>{errors.email.message}</span>}
                            <button className='btn btn-primary btn-sm mt-2 w-100'>Subscribe</button>
                        </form>
                    </div>
                </div>

                <p className='text-muted mb-0 text-center text-sm'>
                    Created by{' '}
                    <a href={appSettings.copyrightsLink} className='text-primary'>
                        {appSettings.copyrightsBrand}
                    </a>{' '}
                    {new Date().getFullYear()}. &copy; All rights reserved
                </p>
            </div>
        </footer>
    );
}

export default Footer;
