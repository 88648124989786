import React from 'react';

// COMPOENENTS
import PageBanner from '../../components/general/PageBanner';
import useApp from '../../hooks/useApp';

function AboutUsPage() {
    const { themeMode } = useApp();

    return (
        <>
            <PageBanner
                heading='About Fundur'
                text='Web3.0 Crowdfunding App - A Decentralized Crowdfunding platform'
            ></PageBanner>

            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row gy-4 gx-5 justify-content-between'>
                                <div className='col-lg-4'>
                                    <h2>Web3.0 Crowdfuning DAPP</h2>
                                    <p className='text-muted'>Fundur launched in 2023</p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>
                                    Create impact and make your mark with the power of crowdfunding.
                                    </p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>
                                    To address the issues of trust and transparency in traditional crowdfunding platforms, we aim to develop a decentralized crowdfunding platform on the blockchain that allows individuals and organizations to securely and transparently raise funds for their projects and initiatives.
                                    </p>
                                    <h5 className='text-primary mb-0'>FUNDUR - Future of Decentralised fundraising</h5>
                                    <p className='text-muted'>FUNDUR CEO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`py-5 ${themeMode === 'light' ? 'bg-light' : 'bg-darker'}`}>
                <div className='container py-5'>
                    <header className='mb-5 text-center'>
                        <h2 className='h1'>Top Features</h2>
                        <p className='text-muted'>Top 3 Features in our app that are unrivaled!</p>
                    </header>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row g-4 text-center'>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>Decentralized</h4>
                                            <p className='text-muted mb-0'>
                                            Fundur platform enables small organisation to raise necessary funds via crowdfunding through smart contract transactions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>Secure</h4>
                                            <p className='text-muted mb-0'>
                                            Fundur is completely secure and user can use our platform securely as it is decentralized and it is a fast way to raise funds with no upfront fees.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h4>Trust & transparency</h4>
                                            <p className='text-muted mb-0'>
                                            To address the issues of trust and transparency in traditional crowdfunding platforms, we aim to develop a decentralized crowdfunding platform on the blockchain that allows individuals and organizations to securely and transparently raise funds for their projects and initiatives.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5'>
                <div className='container text-center py-5'>
                    <div className='row'>
                        <div className='col-lg-6 mx-auto'>
                            <p className='h3 fw-light'>
                                "With FUNDUR - Raise funds for your needs without boundaries."
                            </p>
                            <h5 className='text-primary'>Prabu Mani</h5>
                            <p className='text-muted'>FUNDUR, CEO</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUsPage;
