import React, { useEffect } from 'react';
import PageBanner from '../../components/general/PageBanner';
import SignUpForm from './SignupForm';
import useUser from '../../hooks/useUser';
import useMailer from '../../components/general/Mailer';
import { appSettings } from '../../helpers/settings';

function RegisterPage() {
    const { userInfo } = useUser();
    const { sendEmail } = useMailer();

    /* --------------------------------------------- 
          SEND VERIFICATION EMAIL ON USER REGISTER
    --------------------------------------------- */
    useEffect(() => {
        if (userInfo?.code && !userInfo?.verified) {
            sendEmail(appSettings?.brandName, userInfo?.name, userInfo?.code, userInfo?.email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.code]);

    return (
        <>
            <PageBanner
                heading='Create an account'
                text='Create an account with FUNDUR - Web3 fundraising App'
            ></PageBanner>
            <section className='pb-5 page-first-section'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                            <div className='card p-md-4'>
                                <div className='card-body'>
                                    <header className='text-center mb-4'>
                                        <h4 className='mb-0'>Fill the Form Below</h4>
                                        <p className='text-muted'>To create an account</p>
                                    </header>
                                    <SignUpForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RegisterPage;
